import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _isEmpty from "lodash/isEmpty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es7.object.entries.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import createMemoizedSelector from 'redux/selectors/createMemoizedSelector';
import LazySet from 'utils/cellSets/LazySet';
import initialState from '../../reducers/cellSets/initialState';
var getCellSets = function getCellSets() {
  return function (state) {
    var stateToReturn = state && Object.keys(state).length ? state : initialState;
    var accessible = !stateToReturn.loading && !stateToReturn.initialLoadPending && !stateToReturn.updatingClustering && !stateToReturn.error;
    var properties = stateToReturn.properties;
    var propertiesWithExtraKeys = {};
    var cellIdsToReturn;
    if (!_isEmpty(properties)) {
      Object.entries(properties).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];
        var cellIds = value.cellIds,
          cellSetKeys = value.cellSetKeys;
        if (cellIds) {
          cellIdsToReturn = new LazySet(cellIds);
        } else if (cellSetKeys) {
          cellIdsToReturn = new LazySet();
          cellSetKeys.forEach(function (sampleId) {
            cellIdsToReturn.addSet(properties[sampleId].cellIds, true);
          });
        } else {
          cellIdsToReturn = new LazySet();
        }
        propertiesWithExtraKeys[key] = _objectSpread(_objectSpread({}, value), {}, {
          cellIds: cellIdsToReturn
        });
      });
    }
    return _objectSpread(_objectSpread({}, stateToReturn), {}, {
      properties: propertiesWithExtraKeys,
      accessible: accessible
    });
  };
};
export default createMemoizedSelector(getCellSets);